import {useState, useEffect, useRef, useMemo} from 'react'
import { useRouter } from 'next/router';
import { IoNotificationsOutline } from 'react-icons/io5';
import Link from 'next/link';
import { w3cwebsocket as W3CWebSocket, router } from 'websocket';
import axios from 'axios'

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { updateNotificationLastChecked } from '@/store/slices/userSlice';

import { NotificationCard } from '@/src/cards/NotificationCard';


interface NotificationDropdownProps {

}


export const NotificationDropdown = ({

}:NotificationDropdownProps) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    //get user redux state
    const userData = useAppSelector(state => state.user).data
    const {token,id} = userData.user
    const {notification_last_checked} = userData.user_profile
    const config = useMemo(() => ({
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
    }),[token])

    // update notification_last_checked?

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    /**
     * NOTIFICATION DATA STATE
     */
    const [recentNotifications, setRecentNotifications] = useState<any[]>([])
    const getRecentUserNotifications = async () => {
        if (config) {
            //get env var
            const res = await fetch('/api/env/backend-url')
            const {backendUrl} = await res.json();
            if (!backendUrl) {
                console.error('Backend URL env is missing');
                return 
            }

            axios.get(
                `${backendUrl}/api/notification/get-recent-user-notifications/`,
                config
            )
                .then(response => {
                    const {next, results} = response.data
                    setRecentNotifications(results)
                    // decide whether to set new alert badge
                    // first value of result isn't read yet
                    if (!results[0].is_read) {
                        //compare most recent notif timestamp with last checked
                        const firstTimestamp = new Date(results[0].timestamp)
                        // if last checked is earlier, set new alert
                        if (new Date(notification_last_checked) < firstTimestamp) {
                            setNewAlert(true)
                        }
                    }
                })
                .catch(error => {
                    console.log('error')
                    console.log(error)
                })
        }
    }
    

    //dropdown state
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const toggleDropdown = () => {
        // open dropdown
        setDropdownOpen(!isDropdownOpen);
    };


    /**
     * NEW ALERT STATE
     */
    const [newAlert, setNewAlert] = useState(false)
    // ref to reset scroll when visible
    const divRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (isDropdownOpen) {
            dispatch(updateNotificationLastChecked())
            setNewAlert(false)
            if (divRef.current) {
                divRef.current.scrollTop = 0;
            }
            //get notifications
            getRecentUserNotifications()

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isDropdownOpen])



    /**
     * CLICK OUTSIDE 
     */
    const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };
    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
        window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    /**
     * SOCKET STATE
    */
    // create new socket & save to variable
    const [socket, setSocket] = useState<WebSocket | null>(null);
    useEffect(() => {
        const webSocketUserNotification = async() => {
            if (id) {
                //get env var
                const res = await fetch('/api/env/ws-url')
                const {wsUrl} = await res.json();
                if (!wsUrl) {
                    console.error('Backend WS URL env is missing');
                    return 
                }

                //set up websocket
                const wsUrlFull = `${wsUrl}/ws/user-notification/${id}/`
                const newSocket = new WebSocket(wsUrlFull);
                setSocket(newSocket);
                console.log("websocket connected!!!")
    
                // received notification
                newSocket.onmessage = (event) => {
                    const data = JSON.parse(event.data)
                    // console.log('Received notification:', data);
                    setRecentNotifications((prev) => [data, ...prev])
                    //set new alert
                    setNewAlert(true)
                }
                newSocket.onclose = () => {
                    console.log("websocket connection closed")
                }
                newSocket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                }
    
                return () => {
                    newSocket.close();
                };
            }
        }
        webSocketUserNotification()
    },[id])


    //read notification handler
    const readNotificationHandler = async(id:number) => {
        //get env var
        const res = await fetch('/api/env/backend-url')
        const {backendUrl} = await res.json();
        if (!backendUrl) {
          console.error('Backend URL env is missing');
          return
        }

        axios.patch(
            `${backendUrl}/api/notification/read-user-notification/${id}/`,
            {},
            config
        )
            .then(response => {
                const readNotifList = recentNotifications.map((n) => {
                    if (n.id==id) { n.is_read = true } 
                    return n
                })
                setRecentNotifications(readNotifList)
                setDropdownOpen(false)
            })
            .catch(error => {
                console.log("error reading: ", id)
                console.log(error)
            })
    }


    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        notifications:{
            en:'Notifications',
            jp:'お知らせ'
        },
        see_all: {
            en:'see all',
            jp:'すべて見る'
        },
        no_notifications: {
            en:'You have no new notifications',
            jp:'新しい知らせはありません'
        }
    }


    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <button
                type="button"
                className={`inline-flex items-center space-x-2 text-gray-700 focus:outline-none 
                    p-2 rounded-full cursor-pointer
                    ${isDropdownOpen ? 'bg-gray-300 hover:bg-gray-400' : 'bg-white hover:bg-gray-100'}
                `}
                onClick={toggleDropdown}
            >
                <IoNotificationsOutline 
                    size={22} 
                    className='block cursor-pointer'
                />
                {/** NEW BADGE */}
                {   newAlert ? (
                    <div className="absolute inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white bg-red-600 rounded-full top-2 end-2">
                    </div>
                ) : (<></>)
                }
            </button>

            <div className={`
                absolute -right-12 z-10 mt-1 w-[380px] h-fit max-h-[60vh] overflow-y-scroll
                origin-top-right rounded-md bg-white drop-shadow-lg ring-1 ring-black ring-opacity-5 
                focus:outline-none p-3
                overflow-y-scroll
                ${isDropdownOpen ? 'opacity-100' : 'opacity-0 hidden'}
                transition-all duration-100 ease-in-out
            `} ref={divRef}>
                <div className='flex flex-row justify-between'>
                    <h4 className='text-xl font-futura font-medium'>
                        {text['notifications'][l]}
                    </h4>
                    <Link href='/user/notifications' className=' p-1 hover:bg-gray-200'>
                        <p className='text-sm font-medium'>
                            {text['see_all'][l]}
                        </p>
                    </Link>
                </div>
                <div className='w-full flex flex-col gap-0'>
                    {   recentNotifications.length ? (
                        recentNotifications.map((n, index) => (
                            <NotificationCard 
                                notifObject={n}
                                key={index}
                                onClick={() => readNotificationHandler(n.id)}
                            />
                        )) 
                    ): (
                        <p>{text['no_notifications'][l]}</p>
                    )}
                </div>
                <div className='w-full flex justify-center'>
                    <div className='w-fit h-fit cursor-pointer' onClick={() => router.push('/user/notifications')}>
                        <p className='text-blue-700 hover:font-semibold'>
                            {text['see_all'][l]}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}
